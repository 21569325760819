$(window).on('load', function() {

	"use strict";
	
	var preloader = $('#loading'),
		loader = preloader.find('#loading-center-absolute');
		loader.fadeOut();
		preloader.delay(400).fadeOut('slow');


	var iframevar = document.getElementById('infame');
	var elmnt = iframevar.contentWindow.document.getElementById("headpop");
	elmnt.innerHTML = "";
			
});


$(window).on('scroll', function() {
	
	"use strict";
	
	var b = $(window).scrollTop();
	
	if( b > 80 ){		
		$(".wsmainfull").addClass("scroll");
	} else {
		$(".wsmainfull").removeClass("scroll");
	}				

});


$(document).ready(function() {
		
	"use strict";

	if ( $(window).outerWidth() < 992 ) {
		$('.wsmenu-list li.nl-simple, .wsmegamenu li, .sub-menu li').on('click', function() {
				$('body').removeClass("wsactive");	
				$('.sub-menu').slideUp('slow');
				$('.wsmegamenu').slideUp('slow');	
				$('.wsmenu-click').removeClass("ws-activearrow");
				$('.wsmenu-click02 > i').removeClass("wsmenu-rotate");
		});
	}

	if ( $(window).outerWidth() < 992 ) {
		$('.wsanimated-arrow').on('click', function() {
				$('.sub-menu').slideUp('slow');
				$('.wsmegamenu').slideUp('slow');	
				$('.wsmenu-click').removeClass("ws-activearrow");
				$('.wsmenu-click02 > i').removeClass("wsmenu-rotate");
		});
	}

	var swiper = new Swiper(".twm-slider", {
		slidesPerView: 1,
		speed: 3000,
		grabCursor: true,
		parallax: true,
		loop:true,
		autoplay: true,
		autoplay: {delay: 5000,},
		scrollbar: {
			el: ".swiper-scrollbar",
			hide: false,
		}
	});
	
	$.scrollUp = function (options) {

		var defaults = {
			scrollName: 'scrollUp',
			topDistance: 600,
			topSpeed: 800,
			animation: 'fade',
			animationInSpeed: 200,
			animationOutSpeed: 200,
			scrollText: '',
			scrollImg: false,
			activeOverlay: false
		};

		var o = $.extend({}, defaults, options),
			scrollId = '#' + o.scrollName;

		$('<a/>', {
			id: o.scrollName,
			href: '#top',
			title: o.scrollText
		}).appendTo('body');
		
		if (!o.scrollImg) {
			$(scrollId).text(o.scrollText);
		}
		$(scrollId).css({'display':'none','position': 'fixed','z-index': '99999'});

		if (o.activeOverlay) {
			$("body").append("<div id='"+ o.scrollName +"-active'></div>");
			$(scrollId+"-active").css({ 'position': 'absolute', 'top': o.topDistance+'px', 'width': '100%', 'border-top': '1px dotted '+o.activeOverlay, 'z-index': '99999' });
		}

		$(window).on('scroll', function(){	
			switch (o.animation) {
				case "fade":
					$( ($(window).scrollTop() > o.topDistance) ? $(scrollId).fadeIn(o.animationInSpeed) : $(scrollId).fadeOut(o.animationOutSpeed) );
					break;
				case "slide":
					$( ($(window).scrollTop() > o.topDistance) ? $(scrollId).slideDown(o.animationInSpeed) : $(scrollId).slideUp(o.animationOutSpeed) );
					break;
				default:
					$( ($(window).scrollTop() > o.topDistance) ? $(scrollId).show(0) : $(scrollId).hide(0) );
			}
		});

	};
	
	$.scrollUp();

	$('ul.tabs-1 li').on('click', function(){
		var tab_id = $(this).attr('data-tab');

		$('ul.tabs-1 li').removeClass('current');
		$('.tab-content').removeClass('current');

		$(this).addClass('current');
		$("#"+tab_id).addClass('current');
	});

	$(".accordion > .accordion-item.is-active").children(".accordion-panel").slideDown();
			
	$(".accordion > .accordion-item").on('click', function() {
		$(this).siblings(".accordion-item").removeClass("is-active").children(".accordion-panel").slideUp();
		$(this).toggleClass("is-active").children(".accordion-panel").slideToggle("ease-out");
	});

	$('.count-element').each(function () {
		$(this).appear(function() {
			$(this).prop('Counter',0).animate({
				Counter: $(this).text()
			}, {
				duration: 4000,
				easing: 'swing',
				step: function (now) {
					$(this).text(Math.ceil(now));
				}
			});
		},{accX: 0, accY: 0});
	});

	var owl = $('.brands-1-wrapper');
		owl.owlCarousel({
			items: 2,
			loop:true,
			autoplay:true,
			navBy: 1,
			nav:false,
			autoplayTimeout: 4000,
			autoplayHoverPause: false,
			smartSpeed: 2000,
			responsive:{
				0:{
					items:1
				},
				767:{
					items:1
				},
				768:{
					items:2
				},
				991:{
					items:4
				},
				1000:{
					items:4
				}
			}
	});

	var owl = $('.brands-carousel');
		owl.owlCarousel({
			items: 5,
			loop:true,
			autoplay:true,
			navBy: 1,
			nav:false,
			autoplayTimeout: 4000,
			autoplayHoverPause: false,
			smartSpeed: 2000,
			responsive:{
				0:{
					items:2
				},
				550:{
					items:3
				},
				767:{
					items:3
				},
				768:{
					items:4
				},
				991:{
					items:5
				},				
				1000:{
					items:5
				}
			}
	});

	$(function () {
		$('#whatsapp').floatingWhatsApp({
			phone: '5491121556421',
			popupMessage: 'Hola, ¿como podemos ayudarte?',
			message: "Hola! Quiero solicitar información.",
			showPopup: true,
			showOnIE: false,
			headerTitle: 'Optimus Cargo Courier Oficial',
			// headerColor: 'crimson',
			// backgroundColor: 'crimson',
			// buttonImage: '<img src="burger.svg" />'
		});
	});

});