function log10(a) {
  return Math.log(a) / Math.log(10)
}

function setTitle(a, b) {
  var c = b.split("|");
  setInnerHTML(a, c[2])
}

function getCookie(a) {
  var b = document.cookie;
  a += "=";
  var c = b.indexOf("; " + a);
  if (-1 == c) {
    if (c = b.indexOf(a), 0 != c) return null
  } else c += 2;
  var d = document.cookie.indexOf(";", c); - 1 == d && (d = b.length);
  return unescape(b.substring(c + a.length, d))
}

function setCookie(a, b, c, d, f, g) {
  if (getCookie(a) != b) {
    f = new Date;
    g = new Date;
    if (null == c || 0 == c) c = 365;
    g.setTime(f.getTime() + 864E5 * c);
    document.cookie = a + "=" + escape(b) + (g ? "; expires=" + g.toGMTString() : "") + (d ? "; path=" + d : "")
  }
}

function externalLinks() {
  if (document.getElementsByTagName)
    for (var a = document.getElementsByTagName("a"), b = 0; b < a.length; b++) {
      var c = a[b];
      !c.getAttribute("href") || "nofollow" != c.getAttribute("rel") && "external" != c.getAttribute("rel") || (c.target = "_blank")
    }
}

function LZ(a) {
  return (10 <= a || 0 > a ? "" : "0") + a
}

function Expo(a) {
  return "e" + (0 > a ? "-" : "+") + LZ(Math.abs(a))
}

function Prfx(a, b, c) {
  a += "";
  if (0 < c.length)
    for (; a.length < b;) a = c + a;
  return a
}

function StrU(a, b, c) {
  var d, f = new String(Math.round(a * Number("1e" + c)));
  if (/\D/.test(f)) return "" + a;
  a = new String(Prfx(f, b + c, "0"));
  return a.substring(0, d = a.length - c) + "." + a.substring(d)
}

function Sign(a) {
  return 0 < a ? "" : 0 > a ? "-" : " "
}

function GetSEM(a) {
  a = {
    S: Sign(a),
    E: 0,
    M: void 0 == a ? void 0 : Math.abs(a)
  };
  if (0 == a.M || !isFinite(a.M)) return a;
  for (; 10 <= a.M;) a.E++, a.M /= 10;
  for (; 1 > a.M;) a.E--, a.M *= 10;
  return a
}

function myRoundForced(a, b) {
  if (0 == a) return 0;
  b = Math.round(b) + 1;
  var c = GetSEM(a);
  return (c.S + StrU(c.M, 1, b - 1) + (isFinite(c.M) ? Expo(c.E) : " ")).replace(".e", "e")
}

function myRound(a, b) {
  if (0 == b) a = Math.round(a);
  else {
    var c = Math.pow(10, b);
    a = Math.round(a * c);
    a /= c
  }
  return a
}

function replaceAll(a, b, c) {
  if ("" != b && b != c)
    for (var d = 0, f; d < a.length && (f = a.indexOf(b, d), -1 != f);) d = a.substr(0, f), a = a.substr(f + b.length, a.length), a = d + c + a, d = d.length + c.length;
  return a
}

function convertTable(a, b, c) {
  a = a.split(",");
  b = b.split(",");
  var d = 0,
    f = "",
    g = 0;
  c = c.replace(",", ".");
  for (var q = c == parseFloat(c), h = 0; h < a.length; h++)
    if (c.toUpperCase() == a[h].toUpperCase()) {
      g = h + 1;
      f = "";
      break
    } else q && a[h] == parseFloat(a[h]) && (0 == d ? (d = Math.abs(a[h] - c), f = "~", g = h + 1) : Math.abs(a[h] - c) <= d && (d = Math.abs(a[h] - c), g = h + 1, f = "~"));
  return 0 < g ? f + b[g - 1] : "-"
}

function convertPaper(a, b, c, d) {
  if ("0" == b) return "-";
  a = a.replace(",", ".");
  b = b.replace(",", ".");
  a = a.split("x");
  var f = parseFloat(a[0]);
  var tmpStr = "";
  a = parseFloat(a[1]);
  if ("P" == b) {
    b = f * a;
    a = c.split("x");
    c = parseFloat(a[0]);
    a = parseFloat(a[1]);
    c = b = c * a / b;
    b = myRound(b, d);
    tmpStr = "" + b;
    if (0 == b || 12 < tmpStr.length || 0 < tmpStr.indexOf("e")) b = myRoundForced(c, d);
    return "\u00d7" + b
  }
  c *= parseFloat(b);
  b = f * c;
  a *= c;
  c = b;
  b = myRound(b, d);
  tmpStr = "" + b;
  if (0 == b || 12 < tmpStr.length || 0 < tmpStr.indexOf("e")) b = myRoundForced(c, d);
  b = formatResult(b);
  c = a;
  a = myRound(a,
    d);
  tmpStr = "" + a;
  if (0 == a || 12 < tmpStr.length || 0 < tmpStr.indexOf("e")) a = myRoundForced(c, d);
  a = formatResult(a);
  return b + " x " + a
}

function convertCurrency(a, b, c, d, f, g) {
  var tmpStr = "";
  b = b.split("|");
  var q = b[0];
  b = c.split("|");
  var h = b[0];
  c = b[1];
  b = b[2];
  a = a.replace(",", ".");
  h = h.replace(",", ".");
  q = q.replace(",", ".");
  a = parseFloat(a);
  h = parseFloat(h);
  q = parseFloat(q);
  q = a = a * h / q;
  a = myRound(a, g);
  tmpStr = "" + a;
  if (0 == a || 12 < tmpStr.length || 0 < tmpStr.indexOf("e")) a = myRoundForced(q, g);
  f = isNaN(a) ? "-" : f ? formatResult(a) + " " + b : formatResult(a);
  d ? (f = "<b>" + f + "</b>", setInnerHTML("date", formatDate(c, 2))) : (d = replaceAll(b, " ", "-"), d = replaceAll(d, "/", "-"), d = "value_" + d, document.getElementById("date" +
    d.substring(5, 9)).value = formatDate(c, 2));
  setInnerHTML(d, f)
}

function convert(a, b, c, d, f, g) {
  var tmpStr = "";
  var q = !1,
    h = b.split("|");
  b = h[0];
  var m = h[1],
    h = c.split("|"),
    l = h[0],
    k = h[1];
  c = h[2];
  if ("T" == l) f = convertTable(m, k, a);
  else if ("P" == l) f = convertPaper(k, b, m, g);
  else {
    a = a.replace(",", ".");
    l = l.replace(",", ".");
    b = b.replace(",", ".");
    a = parseFloat(a);
    var n = parseFloat(l),
      h = parseFloat(k),
      k = parseFloat(b),
      m = parseFloat(m);
    if (-888 != h && -888 == m) {
      h = b.split(":");
      k = parseFloat(h[0]);
      m = parseFloat(h[1]);
      var r = Math.pow(10, a / k) / m * n
    } else -888 == h && -888 != m ? 0 == a ? (r = "\u221e", q = !0) : 0 < a && (h = l.split(":"), n = parseFloat(h[0]),
      h = parseFloat(h[1]), r = n * Math.log(h * a / k) * Math.LOG10E) : r = -999 == h && -999 == m ? n / k * a : -999 == h && -999 != m || -999 != h && -999 == m ? n * k / a : 0 == h && 0 == m || 10 != 10 + h + m - h - m ? n / k * a : (n - h) / (k - m) * a + (k * h - m * n) / (k - m);
    if (q) f = r;
    else {
      a = r;
      r = myRound(r, g);
      tmpStr = "" + r;
      if (0 == r || 12 < tmpStr.length || 0 < tmpStr.indexOf("e")) r = myRoundForced(a, g);
      f = isNaN(r) ? "-" : f ? formatResult(r) + " " + remDupSho(c) : formatResult(r)
    }
  }
  d ? f = "<b>" + f + "</b>" : (d = replaceAll(c, " ", "-"), d = replaceAll(d, "/", "-"), d = "value_" + d);
  setInnerHTML(d, f)
}

function addSeparators(a) {
  var b = [];
  a += "";
  var c = a.indexOf(".");
  0 < c ? b = a.split(".", 2) : b[0] = a;
  a = "";
  for (var d = 0, f = b[0].length - 1; 0 <= f; f--) 0 < d && 0 == d % 3 && (a = nT + a), a = b[0].charAt(f) + a, d++;
  0 < c && (a = a + nD + b[1]);
  return a
}

function formatResult(a) {
  var b = ["", ""];
  a + 0 == a ? b[0] = a : b = a.split(" ", 2);
  b[0] + 0 == b[0] || -1 == b[0].indexOf("e") ? a = addSeparators(a) : (a = a.split("e"), a = 1 != parseFloat(a[0]) ? addSeparators(parseFloat(a[0])) + "\u00d710<sup>" + parseFloat(a[1]) + "</sup>" : "10<sup>" + parseFloat(a[1]) + "</sup>", null != b[1] && (a = " " + b[1]));
  return a
}

function externalFormatResult(a, b) {
  var c = a.replace(",", ".");
  result = myRound(parseFloat(c), b);
  return formatResult(result)
}

function initRoman() {
  for (var a = 0; a < initRoman.arguments.length; a++) this[a + 1] = initRoman.arguments[a]
}

function toRoman(a) {
  if (0 >= a || isNaN(a) || 4E6 <= a) return "-";
  var b = new initRoman("", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"),
    c = new initRoman("", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC"),
    d = new initRoman("", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM"),
    f = new initRoman("", "M", "MM", "MMM", 'M<span class="overbar">V</span>', '<span class="overbar">V</span>', '<span  class="overbar">V</span>M', '<span class="overbar">V</span>MM', '<span class="overbar">V</span>MMM', 'M<span class="overbar">X</span>'),
    g = new initRoman("", '<span class="overbar">X</span>', '<span class="overbar">XX</span>', '<span class="overbar">XXX</span>', '<span class="overbar">XL</span>', '<span class="overbar">L</span>', '<span class="overbar">LX</span>', '<span class="overbar">LXX</span>', '<span class="overbar">LXXX</span>', '<span class="overbar">XC</span>'),
    q = new initRoman("", '<span class="overbar">C</span>', '<span class="overbar">CC</span>', '<span class="overbar">CCC</span>', '<span class="overbar">CD</span>', '<span class="overbar">D</span>',
      '<span class="overbar">DC</span>', '<span class="overbar">DCC</span>', '<span class="overbar">DCCC</span>', '<span class="overbar">CM</span>'),
    h = new initRoman("", '<span class="overbar">M</span>', '<span class="overbar">MM</span>', '<span class="overbar">MMM</span>');
  var m = (a - a % 1E6) / 1E6 + 1;
  a %= 1E6;
  var l = (a - a % 1E5) / 1E5 + 1;
  a %= 1E5;
  var k = (a - a % 1E4) / 1E4 + 1;
  a %= 1E4;
  var n = (a - a % 1E3) / 1E3 + 1;
  a %= 1E3;
  var r = (a - a % 100) / 100 + 1;
  a %= 100;
  return h[m] + q[l] + g[k] + f[n] + d[r] + c[(a - a % 10) / 10 + 1] + b[a % 10 + 1]
}

function fromRoman(a) {
  var b = " M MM MMM MMMM MMMMM".split(" "),
    c = " C CC CCC CD D DC DCC DCCC CM".split(" "),
    d = " X XX XXX XL L LX LXX LXXX XC".split(" "),
    f = " I II III IV V VI VII VIII IX".split(" "),
    g = 0,
    q = 0,
    h = 0,
    m = 0,
    l = 0,
    k = 0,
    n;
  a = a.toUpperCase();
  for (n = 1; 6 > n; n++) 0 == a.indexOf(b[n], l) && (m = n, k = b[n].length);
  l += k;
  k = 0;
  for (n = 1; 10 > n; n++) a.indexOf(c[n], l) == l && (h = n, k = c[n].length);
  l += k;
  k = 0;
  for (n = 1; 10 > n; n++) a.indexOf(d[n], l) == l && (q = n, k = d[n].length);
  l += k;
  for (n = 1; 10 > n; n++) a.indexOf(f[n], l) == l && (g = n);
  return 1E3 * m + 100 *
    h + 10 * q + g
}

function removeLastSpace(a) {
  new String;
  a = a.toString();
  a.charAt(a.length - 1) - 1 && (a = a.substr(0, a.length - 1));
  return a
}

function toEnglish(a) {
  if (1E20 < a) return "-";
  var b = "- thousand million billion trilion zillion smillion BIGTHING".split(" "),
    c = "zero one two three four five six seven eight nine".split(" "),
    d = "ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),
    f = "- ten twenty thirty fourty fifty sixty seventy eighty ninety".split(" ");
  a = parseInt(a);
  if (isNaN(a)) return "-";
  var g = new String;
  new String;
  var q = "" + a;
  for (var h = [], m = [], l = 0; l < q.length; l++) h[l] = q.charAt(l), m[l] = 0;
  if (10 > a &&
    -10 < a) return 0 > a && (g += "minus ", a = -a), g += c[a];
  for (l = 0; l < h.length; l++) {
    "-" != h[l] && (m[l] = parseInt(h[l]));
    var k = h.length - l - 1;
    if ("-" == h[l]) g += "Minus ";
    else if (0 < m[l])
      if (2 < k) {
        var n = k - k % 3;
        a = q.substring(l, h.length - n);
        l = h.length - n - 1;
        k = parseInt(k / 3);
        g += toEnglish(a);
        g += " ";
        g += b[k];
        g += " "
      } else 2 == k ? (g += c[m[l]], g += " ", g += "hundred ") : 1 == k ? 1 == m[l] ? (m[l + 1] = parseInt(h[l + 1]), g += d[m[l + 1]], g += " ", l++) : (g += f[m[l]], g += " ") : 0 == k && (g += c[m[l]], g += " ")
  }
  return removeLastSpace(g)
}

function Num2TextConvertor() {}

function toGerman(number) {
  if (number > 1E71) return "-";
  var einsArray = new Array("", "ein", "zwei", "drei", "vier", "f\u00fcnf", "sechs", "sieben", "acht", "neun");
  var zehnArray = new Array("zehn", "elf", "zw\u00f6lf", "dreizehn", "vierzehn", "f\u00fcnfzehn", "sechzehn", "siebzehn", "achtzehn", "neunzehn");
  var zigArray = new Array("", "", "zwanzig", "drei\u00dfig", "vierzig", "f\u00fcnfzig", "sechzig", "siebzig", "achtzig", "neunzig");
  var spec_unitpref = new Array("M", "B", "Tr", "Quadr", "Quint", "Sext", "Sept", "Okt", "Non");
  var unitpref = new Array("Un",
    "Duo", "Tre", "Quattuor", "Quin", "Sex", "Septen", "Okto", "Novem");
  var tenpref = new Array("Dez", "Vigint", "Trigint", "Quadragint", "Quinquagint", "Sexagint", "Septuagint", "Oktogint", "Nonagint");
  var hundredpref = new Array("Zen", "Duzen", "Trezen", "Quadringen", "Quingen", "Sescen", "Septingen", "Oktingen", "Nongen");
  var bigsArray = new Array;
  var index = 0;
  var len = spec_unitpref.length;
  for (var i = 0; i < len; i++) {
    bigsArray[index++] = spec_unitpref[i] + "illion";
    bigsArray[index++] = spec_unitpref[i] + "illiard"
  }
  len = tenpref.length;
  var len2 =
    unitpref.length;
  for (i = 0; i < len; i++) {
    bigsArray[index++] = tenpref[i] + "illion";
    bigsArray[index++] = tenpref[i] + "illiard";
    for (var j = 0; j < len2; j++) {
      bigsArray[index++] = unitpref[j] + tenpref[i].toLowerCase() + "illion";
      bigsArray[index++] = unitpref[j] + tenpref[i].toLowerCase() + "illiard"
    }
  }
  bigsArray[index] = hundredpref[0] + "tillion";
  maxNumLength = bigsArray.length * 3 + 6;
  var numstr = String(number);
  numstr = numstr.replace(/\s+/g, "");
  var msg = "";
  if (numstr.charAt(0) == "-") {
    msg += "minus ";
    numstr = numstr.substring(1)
  } else if (numstr.charAt(0) ==
    "+") numstr = numstr.substring(1);
  var len = numstr.length;
  for (var i = 0; i < len; i++)
    if (numstr.charAt(i) != "0") {
      numstr = numstr.substring(i);
      break
    }
  if (!isValidNatNumber(numstr)) return "-";
  else if (Number(numstr) == 0) return "Null";
  else if (numstr.length > maxNumLength) {
    var diff = numstr.length - maxNumLength;
    return "Support of only " + maxNumLength + " digits. There " + (diff == 1 ? "is one" : "are " + diff) + " more!"
  }
  numstr = "0" + numstr;
  len = numstr.length;
  var h1 = "numstr.charAt(len-pos)";
  var h2 = "numstr.charAt(len-pos+1)";
  var h3 = "Number(numstr.substring(len-pos-2, len-pos+1))";
  var singEnd, bigsIndex;
  for (var pos = len; pos > 0; pos--) switch (pos % 3) {
    case 0:
      if (eval(h1) == "0");
      else if (eval(h1) == "1") msg += "hundert";
      else msg += einsArray[eval(h1)] + "hundert";
      break;
    case 2:
      if (eval(h1) == "0") msg += einsArray[eval(h2)];
      else if (eval(h1) == "1") msg += zehnArray[eval(h2)];
      else if (eval(h2) == "0") msg += zigArray[eval(h1)];
      else msg += einsArray[eval(h2)] + "und" + zigArray[eval(h1)];
      break;
    case 1:
      switch (pos) {
        default: bigsIndex = (pos - 7) / 3;singEnd = bigsIndex % 2 == 0 ? " " : "e ";
        if (eval(h3) == 1) msg += "e " + bigsArray[bigsIndex] + singEnd;
        else if (eval(h3) != 0) msg += " " + bigsArray[bigsIndex] + "en ";
        break;
        case 4:
            if (eval(h3) != 0) msg += "tausend";break;
        case 1:
            if (numstr.substring(len - pos - 1) == "01") msg += "s";break
      }
      break
  }
  return msg
};

function letras(a, b, c) {
  var d = "",
    f = "",
    g = "";
  a = eval(a);
  b = eval(b);
  c = eval(c);
  switch (a) {
    case 0:
      d = "";
      break;
    case 1:
      d = 0 == b && 0 == c ? "Cien" : "Ciento ";
      break;
    case 2:
      d = "Doscientos ";
      break;
    case 3:
      d = "Trescientos ";
      break;
    case 4:
      d = "Cuatrocientos ";
      break;
    case 5:
      d = "Quinientos ";
      break;
    case 6:
      d = "Seiscientos ";
      break;
    case 7:
      d = "Setecientos ";
      break;
    case 8:
      d = "Ochocientos ";
      break;
    case 9:
      d = "Novecientos "
  }
  switch (b) {
    case 0:
      f = "";
      break;
    case 1:
      switch (c) {
        case 0:
          f = "Diez";
          break;
        case 1:
          f = "Once";
          break;
        case 2:
          f = "Doce";
          break;
        case 3:
          f = "Trece";
          break;
        case 4:
          f = "Catorce";
          break;
        case 5:
          f = "Quince";
          break;
        case 6:
          f = "Dieciseis";
          break;
        case 7:
          f = "Diecisiete";
          break;
        case 8:
          f = "Dieciocho";
          break;
        case 9:
          f = "Diecinueve"
      }
      break;
    case 2:
      f = "Veinte";
      break;
    case 3:
      f = "Treinta";
      break;
    case 4:
      f = "Cuarenta";
      break;
    case 5:
      f = "Cincuenta";
      break;
    case 6:
      f = "Sesenta";
      break;
    case 7:
      f = "Setenta";
      break;
    case 8:
      f = "Ochenta";
      break;
    case 9:
      f = "Noventa"
  }
  switch (c) {
    case 0:
      g = "";
      break;
    case 1:
      g = "Uno";
      break;
    case 2:
      g = "Dos";
      break;
    case 3:
      g = "Tres";
      break;
    case 4:
      g = "Cuatro";
      break;
    case 5:
      g = "Cinco";
      break;
    case 6:
      g =
        "Seis";
      break;
    case 7:
      g = "Siete";
      break;
    case 8:
      g = "Ocho";
      break;
    case 9:
      g = "Nueve"
  }
  return 1 == b ? d + f : 0 == b || 0 == c ? d + " " + f + g : 2 == b ? d + "Veinti" + g.toLowerCase() : d + f + " y " + g
}

function getNumberLiteral(a) {
  if (isNaN(a)) return "-";
  parseInt(a / 1E12);
  rm0 = a % 1E12;
  m1 = parseInt(rm0 / 1E11);
  rm1 = rm0 % 1E11;
  m2 = parseInt(rm1 / 1E10);
  rm2 = rm1 % 1E10;
  m3 = parseInt(rm2 / 1E9);
  rm3 = rm2 % 1E9;
  var b = parseInt(rm3 / 1E8);
  r1 = rm3 % 1E8;
  var c = parseInt(r1 / 1E7);
  r2 = r1 % 1E7;
  var d = parseInt(r2 / 1E6);
  r3 = r2 % 1E6;
  var f = parseInt(r3 / 1E5);
  r4 = r3 % 1E5;
  var g = parseInt(r4 / 1E4);
  r5 = r4 % 1E4;
  var q = parseInt(r5 / 1E3);
  r6 = r5 % 1E3;
  var h = parseInt(r6 / 100);
  r7 = r6 % 100;
  var m = parseInt(r7 / 10);
  r8 = r7 % 10;
  var l = parseInt(r8 / 1);
  return 1E12 > a && 1E9 <= a ? (tmp = a.toString(),
    s = tmp.length, tmp1 = tmp.slice(0, s - 9), tmp2 = tmp.slice(s - 9, s), tmpn1 = getNumberLiteral(tmp1), tmpn2 = getNumberLiteral(tmp2), pred = 0 <= tmpn1.indexOf("Un") ? " Bill\u00f3n " : " Billones ", tmpn1 + pred + tmpn2) : 1E10 > a && 1E6 <= a ? (mldata = letras(b, c, d), b = mldata.replace("Un", "*"), 0 > b.indexOf("*") || 3 < b.indexOf("*") ? (mldata = mldata.replace("Uno", "un"), mldata += " Millones ") : mldata = "Un Mill\u00f3n", mdata = letras(f, g, q), cdata = letras(h, m, l), "     " != mdata && (1E6 == a ? mdata = mdata.replace("Uno", "un") : (mdata = mdata.replace("Uno", "un"), 0 !=
    f + g + q && (mdata += " mil "))), mldata + mdata + cdata) : 1E6 > a && 1E3 <= a ? (mdata = letras(f, g, q), cdata = letras(h, m, l), b = mdata.replace("Un", "*"), 0 > b.indexOf("*") || 3 < b.indexOf("*") ? (mdata = mdata.replace("Uno", "un"), mdata + " mil " + cdata) : "Mil " + cdata) : 1E3 > a && 1 <= a ? letras(h, m, l) : 0 == a ? " Cero" : "No disponible"
}

function toSpanish(a) {
  return 1E9 <= a ? "-" : getNumberLiteral(a).toLowerCase()
}

function toSwedish(number) {
  if (number > 1E71) return "-";
  var einsArray = new Array("", "ett", "tv\u00e5", "tre", "fyra", "fem", "sex", "sju", "\u00e5tta", "nio");
  var zehnArray = new Array("tio", "elva", "tolv", "tretton", "fjorton", "femton", "sexton", "sjutton", "arton", "nitton");
  var zigArray = new Array("", "", "tjugo", "trettio", "fyrtio", "femtio", "sextio", "sjuttio", "\u00e5ttio", "nittio");
  var spec_unitpref = new Array("m", "b", "tr", "quadr", "quint", "sext", "sept", "okt", "non");
  var unitpref = new Array("un", "duo", "tre", "quattuor", "quin",
    "sex", "septen", "okto", "novem");
  var tenpref = new Array("Dez", "Vigint", "Trigint", "Quadragint", "Quinquagint", "Sexagint", "Septuagint", "Oktogint", "Nonagint");
  var hundredpref = new Array("Zen", "Duzen", "Trezen", "Quadringen", "Quingen", "Sescen", "Septingen", "Oktingen", "Nongen");
  var bigsArray = new Array;
  var index = 0;
  var len = spec_unitpref.length;
  for (var i = 0; i < len; i++) {
    bigsArray[index++] = spec_unitpref[i] + "iljon";
    bigsArray[index++] = spec_unitpref[i] + "iljard"
  }
  len = tenpref.length;
  var len2 = unitpref.length;
  for (i = 0; i < len; i++) {
    bigsArray[index++] =
      tenpref[i] + "illion";
    bigsArray[index++] = tenpref[i] + "illiard";
    for (var j = 0; j < len2; j++) {
      bigsArray[index++] = unitpref[j] + tenpref[i].toLowerCase() + "illion";
      bigsArray[index++] = unitpref[j] + tenpref[i].toLowerCase() + "illiard"
    }
  }
  bigsArray[index] = hundredpref[0] + "tillion";
  maxNumLength = bigsArray.length * 3 + 6;
  var numstr = String(number);
  numstr = numstr.replace(/\s+/g, "");
  var msg = "";
  if (numstr.charAt(0) == "-") {
    msg += "minus ";
    numstr = numstr.substring(1)
  } else if (numstr.charAt(0) == "+") numstr = numstr.substring(1);
  var len = numstr.length;
  for (var i = 0; i < len; i++)
    if (numstr.charAt(i) != "0") {
      numstr = numstr.substring(i);
      break
    }
  if (!isValidNatNumber(numstr)) return "-";
  else if (Number(numstr) == 0) return "noll";
  else if (numstr.length > maxNumLength) {
    var diff = numstr.length - maxNumLength;
    return "Support of only " + maxNumLength + " digits. There " + (diff == 1 ? "is one" : "are " + diff) + " more!"
  }
  numstr = "0" + numstr;
  len = numstr.length;
  var h1 = "numstr.charAt(len-pos)";
  var h2 = "numstr.charAt(len-pos+1)";
  var h3 = "Number(numstr.substring(len-pos-2, len-pos+1))";
  var singEnd, bigsIndex;
  for (var pos = len; pos > 0; pos--) switch (pos % 3) {
    case 0:
      if (eval(h1) == "0");
      else if (eval(h1) == "1") msg += "etthundra";
      else msg += einsArray[eval(h1)] + "hundra";
      break;
    case 2:
      if (eval(h1) == "0") msg += einsArray[eval(h2)];
      else if (eval(h1) == "1") msg += zehnArray[eval(h2)];
      else if (eval(h2) == "0") msg += zigArray[eval(h1)];
      else msg += zigArray[eval(h1)] + einsArray[eval(h2)];
      break;
    case 1:
      switch (pos) {
        default: bigsIndex = (pos - 7) / 3;singEnd = " ";
        if (eval(h3) == 1) {
          msg = msg.replace("ett", "en");
          msg += " " + bigsArray[bigsIndex] + singEnd
        } else if (eval(h3) !=
          0) msg += " " + bigsArray[bigsIndex] + "er ";
        break;
        case 4:
            if (eval(h3) != 0) msg += "tusen ";break;
        case 1:
            break
      }
      break
  }
  msg = msg.replace("ttt", "tt");
  return msg
};

function toFrench(a) {
  for (a = a.length; 1 <= a; a--);
}

function isValidNatNumber(a) {
  if (!isNaN(a)) {
    var b = String(a);
    a = b.charAt(0);
    b = -1 == b.indexOf(".");
    if ("+" != a && "-" != a && b) return !0
  }
  return !1
}

function populate() {
  return [, "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
}

function transpose(a) {
  N = a.length;
  var b = "";
  for (i = 0; i < N; i++) b += a.substring(N - i - 1, N - i);
  return b
}

function toDecimal(a, b) {
  return "R" == b ? fromRoman(a) : 10 == b || "A" == b ? a : 10 == 10 + parseFloat(b) - parseFloat(b) ? parseInt(a, b) : 0
}

function fromDecimal(a, b) {
  if (0 > a || "" == a) return "-";
  if ("C" == b) return toChinese(a);
  if ("R" == b) return toRoman(a);
  "A" == b && (b = 10);
  if ("24" == b) return toBase24(a);
  if ("en" == b) return toEnglish(a);
  if ("de" == b) return toGerman(a);
  if ("es" == b) return toSpanish(a);
  if ("sv" == b) return toSwedish(a);
  var c = "",
    d = parseFloat(a),
    f = populate();
  for (b = parseFloat(b); d >= b;) {
    var g = d % b;
    d = Math.floor(d / b);
    c += f[g + 1]
  }
  c += f[d + 1];
  return "undefined" == c ? "-" : transpose(c)
}

function convertNumerals(a, b, c, d, f, g) {
  f = b.split("|");
  b = f[0];
  f = c.split("|");
  c = f[0];
  f = f[2];
  a = toDecimal(a, b);
  a = fromDecimal(a, c);
  d ? a = "<b>" + a + "</b>" : (d = replaceAll(f, " ", "-"), d = replaceAll(d, "/", "-"), d = "value_" + d);
  setInnerHTML(d, a)
}

function showDiv(a) {
  document.getElementById(a).style.display = "block"
}

function hideDiv(a) {
  document.getElementById(a).style.display = "none"
}

function cityClick(a, b) {
  showHideClock3(!0, b);
  changeUTC(a, !0, !0, !0, !0, !0, !0, b);
  return !1
}

function changeUTC(a, b, c, d, f, g, q, h) {
  f = a.split("|");
  a = f[0];
  d = f[1];
  f = f[2];
  b && 1 == h && (-1 != navigator.appName.indexOf("Microsoft") ? window.flashObject : window.document.flashObject).goFlash(a);
  if (c && "" != f) {
    if (-1 != navigator.appName.indexOf("Microsoft")) switch (h) {
      case 3:
      case 4:
        b = window.flashObject3;
        break;
      default:
        b = window.flashObject2
    } else switch (h) {
      case 3:
      case 4:
        b = window.document.flashObject3;
        break;
      default:
        b = window.document.flashObject2
    }
    1 == h ? setInnerHTML("city", d) : 4 != h && 5 != h && (document.getElementById("cityfield" +
      h).value = d);
    b.goFlash(a, f)
  }
  if (g && 1 == h) {
    SelectObject = document.getElementById("byutc");
    g = !1;
    for (index = 0; index < SelectObject.length; index++) SelectObject[index].value == a && (SelectObject.selectedIndex = index, g = !0);
    g || (SelectObject.selectedIndex = 0)
  }
  if (q && 1 == h)
    for (index = 0; index < cityDiv.length; index++) cityDiv[index] == a ? showDiv("cities" + index) : hideDiv("cities" + index)
}

function cityChange(a, b, c, d) {
  if (0 == a.value.length) moveIt("citylist", 1, 1, 1, ""), showHideClock3(!0, b);
  else {
    var f = 0,
      g = 0,
      q = stripAccents(a.value.toLowerCase()),
      h = "<ul>";
    a = "show";
    for (var m = 0; m < city.length; m++)
      if (null != city[m]) {
        var l = stripAccents(city[m][0].toLowerCase());
        null != l.match(q) && (20 == f++ && (a += ", scroll"), 20 > f && (g += 14), l = '<a href="/www/convertworld/en/time_zone/' + escape(city[m][0]) + ".html\" onclick=\"moveIt('citylist', 1, 1, 1, ''); return cityClick('" + city[m][1] + "|" + city[m][0].replace("'", "\\'") +
          "|" + city[m][2] + "'," + b + ');">', h = h + '<div class="unit_' + (f % 2 ? "even" : "odd") + '_color">' + l + city[m][0] + "</a></div>\n")
      }
    0 == f && (h = h + '<div style="float: left; width: 100%; white-space: nowrap; padding-left: 10px" class="unit_' + (f % 2 ? "even" : "odd") + '_color">&nbsp;</div>\n', g = 14);
    setInnerHTML("citylist", h + "</ul>");
    c = $("input#" + c).offset();
    d ? moveIt("citylist", c.top - 92, c.left - 160, g, a) : t ? moveIt("citylist", c.top - 92, c.left - 160, g, a) : moveIt("citylist", c.top + 20, c.left, g, a);
    showHideClock3(!1, b)
  }
}

// function setInnerHTML(a, b) {
//   document.getElementById && (document.getElementById(a).innerHTML = b)
// }

function findPos(a) {
  var b = curtop = 0;
  if (a.offsetParent)
    for (b = a.offsetLeft, curtop = a.offsetTop; a = a.offsetParent;) b += a.offsetLeft, curtop += a.offsetTop;
  return [b, curtop]
}

function moveIt(a, b, c, d, f) {
  e = document.getElementById(a);
  e.style.top = b + "px";
  e.style.left = c + "px";
  0 < d && (e.style.height = d + "px");
  null != f.match("show") ? e.style.display = "block" : e.style.display = "none";
  null != f.match("scroll") ? e.style.overflow = "scroll" : e.style.overflow = "hidden"
}

function stripAccents(a) {
  for (var b = [/[\xC0-\xC2]/g, /[\xE0-\xE2]/g, /[\xC8-\xCA]/g, /[\xE8-\xEB]/g, /[\xCC-\xCE]/g, /[\xEC-\xEE]/g, /[\xD2-\xD4]/g, /[\xF2-\xF4]/g, /[\xD9-\xDB]/g, /[\xF9-\xFB]/g], c = "AaEeIiOoUu".split(""), d = 0; d < b.length; d++) a = a.replace(b[d], c[d]);
  return a
}

function showHideClock3(a, b) {
  1 != b && (e = document.getElementById("flashObject3"), a ? (e.width = "150px", e.height = "44px") : a || 2 != b || (e.width = "1px", e.height = "1px"))
}
window.onload = externalLinks;

function formatDate(a, b) {
  var c = a.substring(0, 4);
  var d = a.substring(4, 6);
  var f = a.substring(6, 8);
  switch (b) {
    case 1:
      return "0" == f.charAt(0) && (f = f.charAt(1)), "0" == d.charAt(0) && (d = d.charAt(1)), f + "/" + d;
    case 2:
      return c + "-" + d + "-" + f;
    default:
      return a
  }
}

function converterChange(a, b) {
  var c = $((b ? "#mini-" + a + " " : "") + "select." + (b ? "mini" : "extended") + "-from")[0].selectedIndex,
    d = $((b ? "#mini-" + a + " " : "") + "select." + (b ? "mini" : "extended") + "-to")[0].selectedIndex;
  $((b ? "#mini-" + a + " " : "") + "select." + (b ? "mini" : "extended") + "-from").prop("selectedIndex", d);
  $((b ? "#mini-" + a + " " : "") + "select." + (b ? "mini" : "extended") + "-to").prop("selectedIndex", c);
  $((b ? "#mini-" + a + " " : "") + "div." + (b ? "mini" : "extended") + "-from") && (c = $((b ? "#mini-" + a + " " : "") + "div." + (b ? "mini" : "extended") + "-from > button").html(),
    d = $((b ? "#mini-" + a + " " : "") + "div." + (b ? "mini" : "extended") + "-to > button").html(), $((b ? "#mini-" + a + " " : "") + "div." + (b ? "mini" : "extended") + "-from > button").html(d), $((b ? "#mini-" + a + " " : "") + "div." + (b ? "mini" : "extended") + "-to > button").html(c))
}

function addMini(a) {
  for (var b = $("#mini-wrapper").children().get(), c = 0; c < $("#mini-wrapper").children().length; c++)
    if ($(b[c]).attr("id") == a) {
      $("#" + a).fadeOut(100);
      $("#" + a).fadeIn(300);
      return
    }
  $("#mini-wrapper").prepend('<div class="mini-container" id="' + a + '"><img src="/static/images/loading.gif" alt="Loading..." style="margin:130px 0 0 96px"></div>');
  minisUpdated();
  loadIt(a.substr(5), $("#mini-wrapper"), !0, "on")
}

function loadIt(a, b, c, d) {
  $.ajax({
    type: "GET",
    url: p + "/ajax/mini.php",
    data: "c=" + a + "&l=" + la + "&dyn=" + d,
    async: !0,
    success: function(c) {
      b.children("#mini-" + a).html(c);
      animateMinis()
    }
  })
}

function setInnerHTML(a, b) {
  document.getElementById && (document.getElementById(a).innerHTML = b)
}

function menuArrow(a, b) {
  document.getElementById && ($(a).is(":visible") ? document.getElementById(b).src = aR.src : document.getElementById(b).src = aD.src)
}

function menuToggle(a, b) {
  $(a).slideToggle(200, menuArrow(a, b))
}

function debug(a) {
  $("#debug").append(a + "<br />")
}

function savePos(a, b, c) {
  $.ajax({
    type: "GET",
    url: p + "/ajax/settings.php",
    data: "c1=" + a + "&c2=" + b + "&c3=" + c,
    async: !0,
    success: function(a) {}
  })
}
var tim = (new Date).getTime(),
  ti;

function minisUpdated() {
  (new Date).getTime() < tim + 200 || (tim = (new Date).getTime(), clearTimeout(ti), ti = setTimeout("savePos('" + $("#mini-wrapper").sortable("toArray") + "','','')", 3E3))
}

function showPopup(a) {
  console.log("c: " + a);
  window.open(p + "/converters/popup.php?l=" + la + "&c=" + a, a, "status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=0,scrollbars=0,height=330,width=260")
}

function animateMinis() {
  $(".sortable").sortable({
    handle: ".sortable-handle",
    stop: function() {
      minisUpdated()
    }
  });
  $(".mini-container").hover(function() {
    $(this).children(".mini-tools").find("img").fadeIn(100)
  }, function() {
    $(this).children(".mini-tools").find("img").fadeOut(100)
  });
  $(".close").click(function() {
    $(this).parents(".mini-container").remove();
    minisUpdated()
  });
  $(".new").click(function() {
    var a = $(this).parents(".mini-container");
    showPopup(a.attr("id").substring(5))
  })
}

function addfav(a, b, c) {
  window.sidebar ? window.sidebar.addPanel(b, a, "") : window.opera && window.print ? (c = document.createElement("a"), c.setAttribute("href", a), c.setAttribute("title", b), c.setAttribute("rel", "sidebar"), c.click()) : "Netscape" == navigator.appName ? alert(c) : document.all && window.external.AddFavorite(a, b)
}

function text2short(a) {
  var b = a.lastIndexOf("(");
  if (-1 == b) return a;
  var c = a.lastIndexOf(")");
  if (-1 == c) return a;
  a = a.substring(b + 1, c);
  return remDupSho(a)
}

function remDupSho(a) {
  var b = a.lastIndexOf(":");
  return -1 == b ? a : a.substring(0, b)
}

function contentSize(a) {
  var b = 26;
  a = $("#" + a);
  var c = a.width(),
    d = $("#hidden-resizer");
  d.html(a.html());
  for (d.css("font-size", b); d.width() > c && 8 < b;) d.css("font-size", b), 10 < b ? b-- : b -= .5;
  a.css("font-size", b);
  a.css("margin-bottom", Math.floor(-5 * b / 12 + 130 / 12))
}

function frameSize(a) {
  a = $("#" + a);
  var b = $("#hidden-resizer");
  b.html(a.html());
  b.css("font-size", a.css("font-size"));
  a.width(b.width() + 10);
  return a.width()
}

function resultWidth(a) {
  if (!a) {
    a = frameSize("result-equals");
    var b = Math.floor($(".extended_result_container").width());
    a = Math.floor((b - a - 12) / 2);
    $("#result-from").width(a);
    $("#result-to").width(a)
  }
  contentSize("result-from");
  contentSize("result-to")
}

function obtainHashAmount() {
  if (window.location.hash) {
    var a = parseInt(window.location.hash.substring(1));
    a && $("#amount").val(a)
  }
}

function fetchForDepl(a, b, c) {
  $.ajax({
    url: a + "/ajax/curr.php",
    type: "POST",
    data: "p2=" + c,
    dataType: "text",
    success: b
  })
}

function deplot(a, b) {
  return JSON.parse(JSON.parse(CryptoJS.AES.decrypt(a, b, {
    format: CryptoJSAesJson
  }).toString(CryptoJS.enc.Utf8)))
}

function createPlot(a, b, c, d, f) {
  b = !1 === !b ? p1f : b + p1f;
  fetchForDepl(a, function(a) {
    var g = $.jqplot(d + c + f + 1, [deplot(a, b)], {
      axes: {
        xaxis: {
          renderer: $.jqplot.DateAxisRenderer,
          min: deplot(a, b)[0][0],
          max: deplot(a, b)[deplot(a, b).length - 1][0]
        },
        yaxis: {
          formatString: "%d"
        }
      },
      axesDefaults: {
        tickRenderer: $.jqplot.CanvasAxisTickRenderer,
        tickOptions: {
          fontFamily: "Arial",
          fontSize: "11px"
        }
      },
      seriesDefaults: {
        showMarker: !1
      },
      cursor: {
        show: !0,
        zoom: !0,
        showTooltip: !1
      },
      highlighter: {
        tooltipContentEditor: highlighterCross,
        show: !0,
        sizeAdjust: 7.5,
        tooltipOffset: 9
      },
      grid: {
        background: "#f5f7fc",
        borderWidth: 1,
        shadow: !1,
        drawBorder: !1
      }
    });
    a = $.jqplot(d + c + f + 2, [deplot(a, b)], {
      axes: {
        xaxis: {
          renderer: $.jqplot.DateAxisRenderer,
          min: deplot(a, b)[0][0],
          max: deplot(a, b)[deplot(a, b).length - 1][0]
        }
      },
      axesDefaults: {
        tickRenderer: $.jqplot.CanvasAxisTickRenderer,
        tickOptions: {
          fontFamily: "Arial",
          fontSize: "11px"
        }
      },
      seriesDefaults: {
        showMarker: !1
      },
      cursor: {
        show: !0,
        showTooltip: !1,
        zoom: !0,
        constrainZoomTo: "x"
      },
      grid: {
        background: "#f5f7fc",
        borderWidth: 1,
        shadow: !1,
        drawBorder: !1
      }
    });
    $.jqplot.Cursor.zoomProxy(g,
      a)
  }, b)
}

function highlighterCross(a, b, c, d) {
  return d.data[b][c][0] + ": " + formatResult(myRound(d.data[b][c][1], 4))
};